import axios from "axios";
import { configs } from "../config";

export const logInApi = async (userName, password, accessToken) => {
  if (!userName || !password) {
    return null;
  }
  localStorage.setItem("loginModal", true);
  const options = {
    url: `${configs?.base_url}/services/data/v58.0/query/?q=SELECT Active__c, Daily_Allowance_Type__c, Daily_Allowance__c, Date_of_Birth__c, Designation__c, Distance_Travelled_Km__c, Division__c, Email, EmpId__c, Employee_Name__c, Gender__c, HQ__c, Id, Joining_Date__c, LastName, Level__c, Mobile_Number__c, Name, Password__c, Region_Code__c, Region__c, Reporting_HQ__c, Role__c, State__c, Total_Expense__c, Travel_allowance_Type__c,FCM_Token__c,ReportsTo.FCM_Token__c, Travel_allowance__c, Zone2__c, Zone__c, reportsToId FROM Contact WHERE (EmpId__c = '${userName}' OR Email = '${userName}' OR Mobile_Number__c = '${userName}')  AND Password__c = '${password}'`,
    method: "get",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    console.log(error);
  }
};
