import React from "react";
import SuccessButton from "../Common/SuccessButton";
import { Link } from "react-router-dom";
import CoomonCalender from "../Common/CoomonCalender";
import "./LeaveApplyComponent.css";
import AttendanceDetails from "./Attendance/AttendanceDetails";

const AttendanceComponent = () => {
  return (
    <>
      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-3 col-md-3 col-sm-12"></div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <AttendanceDetails />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-8 col-md-8 col-sm-12"></div>
          <div className="col-lg-4 col-md-4 col-sm-12 leaveButton">
            <Link to="/leaveApply">
              <SuccessButton buttonText="Apply For Leave" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttendanceComponent;
