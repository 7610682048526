import axios from "axios";
import { configs } from "../config";

export const forgotPasswordApi = async ( accessToken, showId ) => {
    const options = {
      url: `${configs?.base_url}/services/data/v58.0/query/?q=SELECT Id FROM Contact+contact+where+EmpId__C='${showId}'`,
      method: "get",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
    };
    try {
      const response = await axios(options);
      return response;
    }
    catch (error) {
      console.log(error)
    }
  };
