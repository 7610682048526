import { TextField } from "@mui/material";
import React from "react";
import CommonDropdown from "../Common/CommonDropdown";
import { useRef } from "react";
import Button from "react-bootstrap/Button";
import "./LeaveApplyComponent.css";
import FailButton from "../Common/FailButton";
import SuccessButton from "../Common/SuccessButton";
import BackButton from "../Common/BackButton";
import CommonButton from "../Common/CommonButton";
import { Helmet } from 'react-helmet';

const LeaveApplyComponent = ({ options, defaultOption }) => {
  const inputRef = useRef(null);
  const handleClick = () => {
    inputRef.current.click();
  };
  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
  };
  return (
    <>
    <Helmet>
        <title>Leave Apply</title>
      </Helmet>
      <div className="container mt-4">
        <BackButton title={'Apply for leave'} />
        <div className="row mt-3">
          <p style={{color:'gray'}}>
            Leave is earned by employee and granted by employer to take time of
            work. The employee is free to avail this leave in accordance with
            the company policy
          </p>
        </div>
        <div className="row mt-3">
          <p><b>Applying for Leave</b></p>
        </div>
        <div className="row mt-3 mb-5">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
        <CommonDropdown
              defaultOption={"Select Leave Type"}
              options={["Earned Leave", "Loss Of Pay", "Comp - Off", "Sabbatical Leave"]}
            /></div>
            </div>
        <div className="row mt-3">
          <div className="col-lg-6 col-md-6 col-sm-6 input-col">
            <TextField
              className="LAC_input"
              label="From Date*"
              color="secondary"
              type="date"
              focused
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 input-col leave-apply-select">
            <CommonDropdown
              defaultOption={"Session 1"}
              options={["Session 2"]}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 input-col">
            <TextField
              className="LAC_input"
              label="To Date*"
              color="secondary"
              type="date"
              focused
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 input-col leave-apply-select">
            <CommonDropdown
              defaultOption={"Session 2"}
              options={["Session 1"]}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 input-col">
            <TextField
              className="LAC_input"
              label="Contact Details*"
              color="secondary"
              type="number"
              focused
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 LAC_colButton input-col">
            <input
              style={{ display: "none" }}
              ref={inputRef}
              type="file"
              onChange={handleFileChange}
            />
            <span className="leaveApply_button">
            <CommonButton onClick={handleClick} title={' Attach File'}/>
            </span>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 input-col">
            <TextField
              className="reason_input"
              label="Reason"
              color="secondary"
              focused
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 input-col">
            <span className="leaveApply_button">
              <CommonButton title={"Submit"} />
            </span>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 LAC_colButton input-col">
            {/* <Button className="cancelButton">Cancel</Button> */}
            <span className="leaveApply_button">
              <FailButton buttonText={"Cancel"} />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveApplyComponent;
