import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./AttendanceDetail.css";
const AttendanceDetails = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());

  const present = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 17, 21, 22, 23, 24, 26,
    27, 29, 30, 31,
  ];
  const leaves = [18, 19, 20];
  const absent = [13, 25, 28];

  const handleMonthClick = (e) => {
    setCurrentMonth(new Date(e.activeStartDate).getMonth());
  };

  return (
    <div className="attendaceDetails_calendar mt-3">
      <Calendar
        onActiveStartDateChange={(e) => handleMonthClick(e)}
        tileClassName={(date) => {
          let day = new Date(date.date).getDate();
          let month = new Date(date.date).getMonth();
          if (month === currentMonth) {
            if (present.includes(day)) {
              return "attendaceDetails_present";
            }
            if (leaves.includes(day)) {
              return "attendaceDetails_leaves";
            }
            if (absent.includes(day)) {
              return "attendaceDetails_absent";
            }
          }
        }}
      />
      <div className="row mt-3 attendaceDetails_footer">
        <div className="col-4">
          {" "}
          <span className="attendaceDetails_footerPresent"></span> Working
        </div>
        <div className="col-4">
          {" "}
          <span className="attendaceDetails_footerAbsent"></span> LOP
        </div>
        <div className="col-4">
          {" "}
          <span className="attendaceDetails_footerLeaves"></span> Approved Leaves
        </div>
      </div>
    </div>
  );
};

export default AttendanceDetails;
