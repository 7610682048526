import React, { useState, useEffect, useRef } from "react";
import "./HamburgerMenu.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import HouseIcon from "@mui/icons-material/House";
import CampaignIcon from "@mui/icons-material/Campaign";
import GroupsIcon from "@mui/icons-material/Groups";
import MessageIcon from "@mui/icons-material/Message";
import EventIcon from "@mui/icons-material/Event";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useSelector, connect } from "react-redux";
import {
  getProfileUrlApi,
  finalProfileUrlApi,
} from "../services/profileImageChangeApi";
import { logoutProfile } from "../Redux/actions/logoutProfile";
import { useNavigate } from "react-router-dom";
import defaultImage from "../assets/images/Default-Profile.jpg";
import { useAuth } from "../AuthContext";
import FeedbackIcon from "@mui/icons-material/Feedback";
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { useDispatch } from "react-redux";

function HamburgerMenu({ setSidebar, sidebar, logoutProfile }) {
  const { handleLogoutAuth } = useAuth();
  const details = useSelector((state) => state?.userDetails?.details);
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const EmployeeId = useSelector((state) => state?.userDetails?.details?.Id);
  const [profileImage, setProfileImage] = useState();
  const navigate = useNavigate();
  const [profImage, setProfImage] = useState(false);
  // const profileUrl = useSelector(
  //   (state) => state?.profileImageUrl?.profileImageUrl
  // );

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       // setLoading(true);
  //       if (!profileUrl) {
  //         setProfImage(true);
  //         setProfileImage(defaultImage);
  //         // setLoading(false);
  //       } else {
  //         setProfileImage(profileUrl);
  //         // setLoading(false);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching or updating profile image:", error);
  //       // setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, [accessToken, EmployeeId]);
  const fileInputRef = useRef(null);

  const dispatch = useDispatch();
  const [isLogout, setIsLogout] = useState(false);
  const handleLogout = () => {
    handleLogoutAuth();
    logoutProfile();
    navigate("/login");
    document.title = "Hester Pharma"
  };

  useEffect(() => {
    if (isLogout) {
      dispatch(logoutProfile());
    }
  }, [isLogout, navigate, dispatch]);


  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  console.log("details-----",details?.Division__c)

  return (
    <Offcanvas
      show={sidebar}
      onHide={() => {
        setSidebar(false);
      }}
      onClick={() => {
        setSidebar(false);
      }}
      style={{ width: "80%", maxWidth: "400px" }}
    >

      <Offcanvas.Body>

        <div className="hamburgerMenu_header" style={{ top: `${scrollPosition}px` }}>
          <div className="hamburgerMenu_close_button">
            <button
              className="btn-close"
              onClick={() => {
                setSidebar(false);
              }}
              aria-label="Close"
            ></button>
          </div>
        </div>

        <div className="hamburgerMenu_profile_box" style={{ top: `${scrollPosition}px` }}>
          <Link to="/myProfile">
            {/* <img
              src={
                profImage
                  ? profileImage
                  : `data:image/jpeg;base64,${profileImage}`
              }
              className="hamburgerMenu_profile"
              alt=""
            /> */}
            <img
              src={defaultImage}
              className="hamburgerMenu_profile"
              alt=""
            />
          </Link>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            ref={fileInputRef}
          // onChange={handleFileChange}
          />
          <p>
            <b>{details?.Name}</b>
          </p>
          <p style={{ fontSize: "15px", fontFamily: 'GilroyMedium' }}>{details?.Mobile_Number__c}</p>
          <p style={{ fontSize: "15px", fontFamily: 'GilroyMedium' }}>{details?.Email}</p>
        </div>



        <hr />
        <div className="hamburgerMenu" style={{ marginLeft: "0px" }}>
          <Link
            to="/home"
            className="hamburgerMenu_link"
            onClick={() => {
              setSidebar(false);
            }}
          >
            <p>
              <HouseIcon className="hamburgerMenu_icon" />
              Home
            </p>
          </Link>
          <Link to="/monthlyTour" className="hamburgerMenu_link">
            <p>
              <CampaignIcon className="hamburgerMenu_icon" />
              New Tour Plan
            </p>
          </Link>
          <Link to="/customers" className="hamburgerMenu_link">
            <p>
              <GroupsIcon className="hamburgerMenu_icon" />
              Customers
            </p>
          </Link>
          {/* {details?.Designation__c !== "VSE" && (
            <Link to="/approve-claims" className="hamburgerMenu_link">
              <p>
                <WysiwygIcon className="hamburgerMenu_icon" />
                Approve Claims
              </p>
            </Link>
          )} */}
          <Link to="/expenseClaim" className="hamburgerMenu_link">
            <p>
              <WysiwygIcon className="hamburgerMenu_icon" />
              Expense Claims
            </p>
          </Link>

          {(details?.Designation__c === "VSE" && details?.Division__c === "PET") ? (

            <Link to="/view-all-claims" className="hamburgerMenu_link">
              <p>
                <WysiwygIcon className="hamburgerMenu_icon" />
                View All Claims
              </p>
            </Link>)
            : (details?.Designation__c === "VSE" || details?.Designation__c === "BE") ? "" :
              (<Link to="/view-all-claims" className="hamburgerMenu_link">
                <p>
                  <WysiwygIcon className="hamburgerMenu_icon" />
                  View All Claims
                </p>
              </Link>
              )}
          <Link
            to="/eDetailing/Dr. Rajeev Verma"
            className="hamburgerMenu_link"
          >
            <p>
              <MessageIcon className="hamburgerMenu_icon" />
              eDetailing
            </p>
          </Link>
          {(details?.Designation__c !== "VSE" && details?.Designation__c !== "BE") && (
            <Link to="/my-mr" className="hamburgerMenu_link">
              <p>
                <Diversity3Icon className="hamburgerMenu_icon" />
                My Team
              </p>
            </Link>
          )}
          <Link to="/dcr-survey" className="hamburgerMenu_link">
            <p>
              <QueryStatsIcon className="hamburgerMenu_icon" />
              DCR Survey
            </p>
          </Link>
          {/* {(details?.Designation__c === "VSE" && details?.Division__c === "PET") ? (
            <Link to="/dcr-manager-list" className="hamburgerMenu_link">
              <p>
                <QueryStatsIcon className="hamburgerMenu_icon" />
                DCR List
              </p>
            </Link>
          )
            : (details?.Designation__c === "VSE" || details?.Designation__c === "BE") ? "" :
              (<Link to="/dcr-manager-list" className="hamburgerMenu_link">
                <p>
                  <QueryStatsIcon className="hamburgerMenu_icon" />
                  DCR List
                </p>
              </Link>
              )} */}
          {(details?.Designation__c === "VSE" && details?.Division__c === "PET") ? (
            <Link to="/feedback-form" className="hamburgerMenu_link">
              <p>
                <FeedbackIcon className="hamburgerMenu_icon" />
                Feedback Form
              </p>
            </Link>
          )
            : (details?.Designation__c === "VSE" || details?.Designation__c === "BE") ? "" :
              (
                <Link to="/feedback-form" className="hamburgerMenu_link">
                  <p>
                    <FeedbackIcon className="hamburgerMenu_icon" />
                    Feedback Form
                  </p>
                </Link>
              )
          }

          <Link to="/reports" className="hamburgerMenu_link">
            <p>
              <ContentPasteSearchIcon className="hamburgerMenu_icon" />
              Reports
            </p>
          </Link>
          <Link
            to="/myProfile"
            className="hamburgerMenu_link"
            onClick={() => {
              setSidebar(false);
            }}
          >
            <p>
              <AccountCircleIcon className="hamburgerMenu_icon" />
              My Account
            </p>
          </Link>
          <span
            className="hamburgerMenu_link"
            onClick={handleLogout}
            style={{ cursor: "pointer" }}
          >
            <p>
              <PowerSettingsNewIcon className="hamburgerMenu_icon" />
              Logout
            </p>
          </span>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
  // } else {
  //   return null;
  // }
}

export default connect(null, { logoutProfile })(HamburgerMenu);
