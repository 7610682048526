import React, { useState } from "react";
import "./App.css";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "./firebase/firebaseConfig";

import Home from "./Pages/Home";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import SignUp from "./Pages/SignUp";
import Login from "./Pages/Login";
import HamburgerMenu from "./Pages/HamburgerMenu";
import MyProfilePage from "./Pages/MyProfilePage";
import MarkAttendance from "./Pages/MarkAttendance";
import AddNewDoctor from "./Components/VisitDetails/AddNewDoctor";
import BottomNavbar from "./Common/BottomNavbar";
import { useEffect } from "react";
import Doctors360 from "./Pages/Doctors360";
import PageNotFound from "./Pages/PageNotFound";
import EDetailingPage from "./Pages/EDetailingPage";
import Campaign from "./Pages/Campaign";
import CreateMTP from "./Pages/CreateMTP";
import MonthlyTourPlanPage from "./Pages/MonthlyTourPlanPage";
import CreateExpenseClaim from "./Components/Expense/CreateExpenseCliam";
import ExpenseClaim from "./Pages/ExpenseClaim";
import Reports from "./Pages/Reports";
import InputInventoryPage from "./Pages/InputInventoryPage";
import DCRSurvey from "./Pages/DCRSurvey";
import CustomersPage from "./Pages/CustomersPage";
import Attendance from "./Pages/Attendance";
import LeaveApply from "./Pages/LeaveApply";
import SyncData from "./Pages/SyncData";
import SplashScreen from "./Common/SplashScreen";
import Modules from "./Pages/Modules";
import PlanMTP from "./Components/MonthlyTour/PlanMTP";
import Header from "./Common/Header";
import EnterRcpa from "./Components/Doctor360/EnterRcpa";
import useWindowSize from "./CustomHooks/useWidowSize";
import CreatePOB from "./Components/StockItem";
import DeviceScreen from "./Common/DeviceScreen";
import Rehersal from "./Components/EDetailing/Rehersal";
import Elearning from "./Pages/Elearning";
import PersonalizedLearning from "./Pages/PersonalizedLearning";
import LMS from "./Pages/LMS";
import CommunityAndMentorship from "./Pages/CommunityAndMentorship";
import { useSelector } from "react-redux";
import ViewNTP from "./Components/MonthlyTour/ViewNTP";
import FullVisitHistory from "./Components/Doctor360/FullVisitHistory";
import FullVisitFrequency from "./Components/Doctor360/FullVisitFrequency";
import ManagerApprovePlans from "./Pages/ManagerApprovePlans";
import { ApproveClaim } from "./Components/Expense/ApproveClaim";
import { AllClaims } from "./Components/Expense/AllClaims";
import ViewPlannedMTP from "./Components/MonthlyTour/ViewPlannedMTP";
import ViewApprovePlans from "./Components/MonthlyTour/ViewApprovePlans";
import { AuthProvider } from "./AuthContext";
import { useAuth } from "./AuthContext";
import FeedbackForm from "./Components/FeedbackForm";
import DccrAllList from "./Pages/DccrAllList";
import MyMr from "./Components/MyMr/MyMr";
import { toast } from "react-toastify";
import { sendFirebaseNotificationApi } from "./services/firebase/sendFirebaseNotification";
import { NotificationMessage } from "./Components/NotificationMessage";
import { saveFcmTokenApi } from "./services/firebase/saveFcmTokenApi";
import ViewAllClaims from "./Components/Expense/ViewAllClaims";
import DcrManagerList from "./Pages/DcrManagerList";

function App() {
  const [sidebar, setSidebar] = useState(false);
  // const [showFooter, setShowFooter] = useState(true);
  const [showHeader, setShowHeader] = useState(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const location = useLocation();
  const empId = useSelector((state) => state?.userDetails?.details?.EmpId__c);
  const userDetails = useSelector((state) => state?.userDetails?.details);
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const { isLoggedIn, handleLoginAuth, handleLogoutAuth } = useAuth();
  // const windowSize = useWindowSize();
  const navigate = useNavigate();
  const isPresent = localStorage.getItem("isLoggedIn");
  useEffect(() => {
    if (location.pathname === "/login" || location.pathname === "/signup") {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    setTimeout(() => {
      setShowSplashScreen(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (location.pathname === "/login" && isPresent) {
      navigate("/home");
    }
  }, [location.pathname, isPresent]);

  let isMobile = false;
  let isLandsape = true;

  async function requestPermission() {
    //requesting permission using Notification API
    if (isLoggedIn) {
      const permission = await Notification.requestPermission();

      if (permission === "granted") {
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_VAPID_KEY,
        });
        // We can send token to server
        if (token !== userDetails.FCM_Token__c) {
          try {
            const response = await saveFcmTokenApi(
              accessToken,
              token,
              userDetails.Id
            );
          } catch (error) {
            console.log(error);
          }
        }
      } else if (permission === "denied") {
        //notifications are blocked
        console.log("You denied for the notification");
      }
    }
  }
  const [isSignedIn, setIsSignIn] = useState(false);
  useEffect(() => {
    requestPermission();
  }, [isLoggedIn]);

  onMessage(messaging, (payload) => {
    toast(<NotificationMessage notification={payload} />);
  });
  function runAtSpecificTime(fn) {
    const now = new Date();
    const specificTime = new Date(now);
  
    specificTime.setHours(0, 1, 0, 0);
  
    if (now.getTime() > specificTime.getTime()) {
      specificTime.setDate(specificTime.getDate() + 1);
    }
  
    const timeUntilSpecificTime = specificTime.getTime() - now.getTime();
  
    const timeoutID = setTimeout(() => {
      const lastRunDate = localStorage.getItem('lastRunDate');
      const today = new Date().toISOString().split('T')[0];
  
      if (lastRunDate !== today) {
        fn();
        localStorage.setItem('lastRunDate', today); // Update the last run date
      }
  
      runAtSpecificTime(fn);
    }, timeUntilSpecificTime);
  
    return timeoutID;
  }
  const handleLogout = () => {
    setIsSignIn(false); 
    localStorage.setItem('isSignedIn', false);
    localStorage.removeItem('isLoggedIn'); 
    handleLogoutAuth();
    navigate("/login");
  };
  useEffect(() => {
    const timeoutID = runAtSpecificTime(() => {
      handleLogout();
    });
  
    return () => clearTimeout(timeoutID);
  }, []);
  useEffect(() => {
    const isSignedInFromStorage = localStorage.getItem("isSignedIn") === "true";
    setIsSignIn(isSignedInFromStorage);
  }, []);
        
  // if (windowSize.width < 768) {
  //   isMobile = true;
  // }

  // if (windowSize.width < windowSize.height) {
  //   isLandsape = false;
  // }

  // if (windowSize.width > windowSize.height) {
  //   if (windowSize.height < 450) {
  //     isMobile = true;
  //   }
  // }

  if (isMobile) {
    return (
      <>
        <DeviceScreen
          text={"App is not supported on mobile. Please use tablet or desktop"}
        />
      </>
    );
  }

  if (!isMobile && !isLandsape) {
    return (
      <>
        <DeviceScreen
          text={"App is not supported on potrait mode. Please use landscape"}
        />
      </>
    );
  }

  if (!isMobile && isLandsape) {
    return (
      <>
        {showSplashScreen && <SplashScreen />}
        {!showSplashScreen && (
          <div className="App">
            {showHeader && <Header setSidebar={setSidebar} sidebar={sidebar} />}
            <HamburgerMenu setSidebar={setSidebar} sidebar={sidebar} />
            <Routes>
              <Route
                path="/hamburgerMenu"
                exact
                element={empId ? <HamburgerMenu /> : <Navigate to="/login" />}
              />
              <Route
                path="/home"
                element={empId ? <Home /> : <Navigate to="/login" />}
              />
              <Route path="/signup" exact element={<SignUp />} />
              <Route
                path="/login"
                exact
                element={isLoggedIn ? <Navigate to="/home" /> : <Login />}
              />
              <Route path="*" exact element={<Navigate to="/login" />} />
              <Route
                path="/myprofile"
                exact
                element={empId ? <MyProfilePage /> : <Navigate to="/login" />}
              />
              <Route
                path="/markAttendance"
                exact
                element={<MarkAttendance />}
              />
              {/* <Route path="/" element={<Home />} /> */}
              <Route
                path="/addNewdoctor"
                exact
                element={empId ? <AddNewDoctor /> : <Navigate to="/login" />}
              />
              <Route
                path="/doctors360/:doctorId"
                exact
                element={empId ? <Doctors360 /> : <Navigate to="/login" />}
              />
              <Route
                path="/pageNotFound"
                exact
                render={() =>
                  empId ? <PageNotFound /> : <Navigate to="/login" />
                }
              />

              <Route
                path="/eDetailing/:doctorName"
                exact
                element={empId ? <EDetailingPage /> : <Navigate to="/login" />}
              />
              <Route
                path="/rehearsal/:doctorName"
                exact
                element={<Rehersal />}
              />
              <Route path="/campaign" exact element={<Campaign />} />
              <Route path="/createMTP" exact element={<CreateMTP />} />
              <Route path="/approve-claims" exact element={<ApproveClaim />} />
              <Route path="/all-claims" exact element={<AllClaims />} />
              <Route
                path="/approve-plans"
                exact
                element={<ManagerApprovePlans />}
              />
              <Route path="/approve-claims" exact element={<ApproveClaim />} />
              <Route path="/all-claims" exact element={<AllClaims />} />
              <Route
                path="/monthlyTour"
                exact
                element={
                  empId ? <MonthlyTourPlanPage /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/plan-mtp/:tourNo?"
                exact
                element={empId ? <PlanMTP /> : <Navigate to="/login" />}
              />
              <Route
                path="/view-mtp/:tourNo?"
                exact
                element={empId ? <ViewPlannedMTP /> : <Navigate to="/login" />}
              />
              <Route
                path="/view-ntp/:date"
                exact
                element={empId ? <ViewNTP /> : <Navigate to="/login" />}
              />
              <Route
                path="/view-tours/:tourNo?/:id?"
                exact
                element={
                  empId ? <ViewApprovePlans /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/expenseClaim"
                exact
                element={empId ? <AllClaims /> : <Navigate to="/login" />}
              />
              <Route
                path="/reports"
                exact
                element={empId ? <Reports /> : <Navigate to="/login" />}
              />
              <Route
                path="/dcr-survey/:doctorId?"
                exact
                element={empId ? <DCRSurvey /> : <Navigate to="/login" />}
              />
              <Route
                path="/leaves"
                exact
                element={empId ? <Attendance /> : <Navigate to="/login" />}
              />
              <Route
                path="/leaveApply"
                exact
                element={empId ? <LeaveApply /> : <Navigate to="/login" />}
              />
              <Route
                path="/my-mr"
                exact
                element={empId ? <MyMr /> : <Navigate to="/login" />}
              />
              <Route
                path="/createExpenseClaim"
                exact
                element={
                  empId ? <CreateExpenseClaim /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/inputInventory"
                exact
                element={
                  empId ? <InputInventoryPage /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/customers"
                exact
                element={empId ? <CustomersPage /> : <Navigate to="/login" />}
              />
              <Route
                path="/sync"
                exact
                element={empId ? <SyncData /> : <Navigate to="/login" />}
              />
              <Route
                path="/modules"
                exact
                element={empId ? <Modules /> : <Navigate to="/login" />}
              />
              <Route
                path="/enterRcpa"
                exact
                element={empId ? <EnterRcpa /> : <Navigate to="/login" />}
              />
              <Route
                path="/create-pob"
                exact
                element={empId ? <CreatePOB /> : <Navigate to="/login" />}
              />
              <Route
                path="/e-learning"
                exact
                element={empId ? <Elearning /> : <Navigate to="/login" />}
              />
              <Route
                path="/personalized-learning"
                exact
                element={
                  empId ? <PersonalizedLearning /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/lms"
                exact
                element={empId ? <LMS /> : <Navigate to="/login" />}
              />
              <Route
                path="/community-and-mentorship"
                exact
                element={
                  empId ? <CommunityAndMentorship /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/customers"
                exact
                element={empId ? <CustomersPage /> : <Navigate to="/login" />}
              />
              <Route
                path="/sync"
                exact
                element={empId ? <SyncData /> : <Navigate to="/login" />}
              />
              <Route
                path="/modules"
                exact
                element={empId ? <Modules /> : <Navigate to="/login" />}
              />
              <Route
                path="/enterRcpa"
                exact
                element={empId ? <EnterRcpa /> : <Navigate to="/login" />}
              />
              <Route
                path="/create-pob"
                exact
                element={empId ? <CreatePOB /> : <Navigate to="/login" />}
              />
              <Route
                path="/e-learning"
                exact
                element={empId ? <Elearning /> : <Navigate to="/login" />}
              />
              <Route
                path="/personalized-learning"
                exact
                element={
                  empId ? <PersonalizedLearning /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/lms"
                exact
                element={empId ? <LMS /> : <Navigate to="/login" />}
              />
              <Route
                path="/community-and-mentorship"
                exact
                element={
                  empId ? <CommunityAndMentorship /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/full-visit-history"
                exact
                element={
                  empId ? <FullVisitHistory /> : <Navigate to="/login" />
                }
              />

              <Route
                path="/full-visit-frequency"
                exact
                element={
                  empId ? <FullVisitFrequency /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/feedback-form"
                exact
                element={empId ? <FeedbackForm /> : <Navigate to="/login" />}
              />
              <Route
                path="/dcr-all-list"
                exact
                element={empId ? <DccrAllList /> : <Navigate to="/login" />}
              />
              <Route
                path="/dcr-manager-list"
                exact
                element={empId ? <DcrManagerList /> : <Navigate to="/login" />}
              />
              <Route
                path="/view-all-claims"
                exact
                element={empId ? <ViewAllClaims /> : <Navigate to="/login" />}
              />
            </Routes>
            {/* {showFooter && (
              <BottomNavbar setSidebar={setSidebar} sidebar={sidebar} />
            )} */}
          </div>
        )}
      </>
    );
  }
}

export default App;
