import { combineReducers } from "redux";
import { accessTokenReducer } from "./utilityReducer";
import { loginReducer, blankRecordReducer, regularizationIdReducer,logoutReducer,profileImageReducer,actualBudgetReducer, signInTimeReducer } from "./utilityReducer";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["accessToken", "userDetails", "blankRecordId","logoutReducer","profileImageUrl","actualBudget","signInTime"]
};

const reducers = combineReducers({
  accessToken: accessTokenReducer,
  userDetails: loginReducer,
  blankRecordId: blankRecordReducer,
  logoutReducer:logoutReducer,
  profileImageUrl: profileImageReducer,
  actualBudget: actualBudgetReducer,
  signInTime: signInTimeReducer
  // regularizationId: regularizationIdReducer
});

export default persistReducer(persistConfig, reducers);
